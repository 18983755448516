/* Screens Resolution : 992px
-------------------------------------------------------------------------- */
@media only screen and (max-width: 1200px) {
  .regular-slider {
    height: 400px;
    background-position: 50% 70%;
  }

  .pre-title {
    font-size: 2.7em;
  }

  .slider-content .date {
    margin-bottom: 10px;
    font-size: 2.2em;
  }
}

/* Screens Resolution : 992px
-------------------------------------------------------------------------- */
@media only screen and (max-width: 992px) {
  /* ---------------------------------
	1. PRIMARY STYLES
	--------------------------------- */

  h1 {
    font-size: 4em;
  }
  h2 {
    font-size: 3em;
  }
  h3 {
    font-size: 2em;
    margin-top: 0;
  }

  /* ---------------------------------
	3. MENU
	--------------------------------- */

  .regular-slider {
    height: 300px;
    background-position: 50% 70%;
  }

  .pre-title {
    font-size: 2.4em;
  }

  .slider-content .date {
    margin-bottom: 10px;
    font-size: 2em;
  }

  .menu-logo {
    margin-top: -1px;
  }
}

@media only screen and (max-device-width: 991px), only screen and (max-width: 991px) {
  .mobile-shell {
    width: 100% !important;
    min-width: 100% !important;
  }

  .center {
    margin: 0 auto !important;
  }

  .td {
    width: 100% !important;
    min-width: 100% !important;
  }

  .td2 {
    width: 100% !important;
    height: 50% !important;
    min-width: 100% !important;
    padding: 10px !important;
  }

  .m-td,
  .m-hide {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    font-size: 0 !important;
    line-height: 0 !important;
    min-height: 0 !important;
  }

  .p30-15 {
    padding: 30px 15px !important;
  }

  .mp0 {
    padding: 0px !important;
  }

  .fluid-img img {
    width: 100% !important;
    max-width: 100% !important;
    height: auto !important;
  }

  .column,
  .column-top,
  .column-dir,
  .column-empty,
  .column-empty2,
  .column-empty3,
  .column-bottom,
  .column-dir-top,
  .column-dir-bottom {
    float: left !important;
    width: 100% !important;
    display: block !important;
  }

  .column-empty {
    padding-bottom: 10px !important;
  }

  .content-spacing {
    width: 15px !important;
    height: 225px !important;
  }

  .vam {
    vertical-align: middle !important;
  }
}

/* Screens Resolution : 767px
-------------------------------------------------------------------------- */
@media only screen and (max-width: 767px) {
  .text2 {
    line-height: 1.6 !important;
  }

  .icon-bars {
    margin-top: -5px;
  }

  p.desc {
    /*padding-bottom: 30px;*/
  }

  .regular-slider {
    height: 200px;
    background-position: 50% 70%;
  }

  .date {
    font-size: 0.9em;
  }

  .menu-logo {
    margin-top: -8px;
    font-size: 2em;
  }

  .slider-content .date {
    margin-bottom: 10px;
    font-size: 1.8em;
  }

  /* ---------------------------------
	1. PRIMARY STYLES
	--------------------------------- */

  p {
    line-height: 1.6;
  }

  h1 {
    font-size: 3.2em;
  }
  h2 {
    font-size: 2.5em;
  }
  h3 {
    font-size: 1.8em;
  }
  h4 {
    font-size: 1.5em;
  }
  h5 {
    font-size: 1.2em;
  }

  .pre-title {
    font-size: 2.1em;
  }

  /* ---------------------------------
	2. COMMONS FOR PAGE DESIGN
	--------------------------------- */

  .section {
    /*padding: 50px 0 20px;*/
  }

  .section .heading {
    padding-bottom: 20px;
  }

  .section .heading .heading-bottom {
    margin-top: 10px;
  }

  .section .heading .heading-bottom-dark {
    margin-top: 10px;
  }

  /* ---------------------------------
	3. MENU
	--------------------------------- */

  /*.menu-container {*/
  /*  margin-top: 20px;*/
  /*}*/

  header {
    border-bottom: none;
  }

  header .logo {
    margin: 10px 0;
    height: 40px;
  }

  /* BOTTOM AREA */

  /*.main-menu-start {*/
  /*  opacity: 0;*/
  /*  transition: 0.5s;*/
  /*}*/

  /*.main-menu-end {*/
  /*  opacity: 1;*/
  /*  transition: 0.5s;*/
  /*}*/

  header .main-menu {
    text-align: left;
    display: none;
    float: none;
    clear: both;
    max-height: 400px;
    overflow: auto;
    border-top: 1px solid #ccc;
    background: #333;
  }

  header .main-menu > li {
    display: block;
    float: none;
    border-bottom: 1px solid #444;
  }

  header .main-menu > li:last-child {
    border-bottom: 0;
  }

  header .main-menu li > a {
    display: block;
    border: 0;
    line-height: 1;
    height: auto;
    padding: 15px;
    background: none;
  }

  /* DROPDOWN  */

  header .main-menu li.drop-down > ul.drop-down-menu {
    position: static;
    box-shadow: none;
  }

  header .main-menu li.drop-down > ul.drop-down-inner li:first-child {
    border-top: 1px solid #444;
  }

  /* DROPDOWN HOVER */

  header .main-menu li.drop-down > ul.drop-down-menu li a {
    padding-left: 25px;
  }

  header .main-menu li.drop-down > ul.drop-down-inner li a {
    padding-left: 35px;
  }

  /* NAV ICON */

  .menu-nav-icon {
    display: inline-block;
    float: right;
  }

  /* ---------------------------------
  5. CONTENT AREA
  --------------------------------- */

  .regular-area {
    padding: 0 0 50px 0;
    margin-top: -50px;
  }

  .regular-area .content {
    padding: 20px 30px;
  }

  .content .quoto-area {
    padding-left: 30px;
  }

  .content .img-wrapper {
    margin: 30px 0 0;
  }

  /* ---------------------------------
	7. WEDDING DETAILS AREA
	--------------------------------- */

  .wedding-details {
    margin-left: 15px;
    overflow: visible;
  }

  .wedding-details:after {
    left: 0;
    margin-left: 0px;
  }

  .w-detail {
    width: 100%;
    padding: 0 0 0 30px;
    margin: 0 0 30px;
  }

  .w-detail.left,
  .w-detail.right {
    text-align: left;
    float: none;
  }

  .w-detail.right:after,
  .w-detail.left:after {
    left: 0;
    margin-left: -9px;
  }

  /* ---------------------------------
	9. CONTACT AREA
	--------------------------------- */

  .contact-area .contact-wrapper {
    width: 100%;
    float: none !important;
  }

  .contact-area .map-area {
    height: 400px;
    width: 100%;
    position: static;
  }

  /* ---------------------------------
	10. VIEW GALLERY
	--------------------------------- */

  .galery-area .gallery-btn {
    margin-top: 10px;
  }

  /* ---------------------------------
	11. FOOTER
	--------------------------------- */

  footer ul > li > a {
    padding: 10px 7px;
  }

  footer .newsletter .email-area {
    width: 90%;
  }

  .overlay {
    padding-top: 20px;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.6);
    overflow-x: hidden;
    transition: 0.5s;
    transition-property: background-color;
  }

  .menu-nav-icon {
    margin-top: -3px;
  }

  .h5 {
    font-size: 14px !important;
  }
  .h4 {
    line-height: 1 !important;
    font-size: 23px !important;
  }
  .acc-columns {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
}

/* Screens Resolution : 479px
-------------------------------------------------------------------------- */
@media only screen and (max-width: 479px) {
  /* ---------------------------------
	1. PRIMARY STYLES
	--------------------------------- */

  body {
    /*font-size: 14px;*/
  }

  .menu-nav-icon {
    margin-top: -5px;
  }
}

/* Screens Resolution : 359px
-------------------------------------------------------------------------- */
@media only screen and (max-width: 359px) {
}

/* Screens Resolution : 290px
-------------------------------------------------------------------------- */
@media only screen and (max-width: 290px) {
}
