/*
====================================================

* 	[Master Stylesheet]

	Theme Name :
	Version    :
	Author     :
	Author URI :

====================================================

	TOC

	1. PRIMARY STYLES
	2. COMMONS FOR PAGE DESIGN
		JQUERY LIGHT BOX
	3. MENU
		MIDDLE AREA
		BOTTOM AREA
		DROPDOWN MENU STYLING
		DROPDOWN SUBMENU
		DROPDOWN HOVER
	4. SLIDER
	5. STORY AREA
	6. REMAINING TIME
	7. WEDDING DETAILS AREA
	8. WEDDING CEREMONY
	9. CONTACT AREA
	10. VIEW GALLERY
	11. FOOTER

====================================================

/* ---------------------------------
1. PRIMARY STYLES
--------------------------------- */

.text2 {
  line-height: 2 !important;
}

.h5 {
  font-size: 18px !important;
}
.h4 {
  line-height: 1 !important;
  font-size: 28px !important;
}

html {
  font-size: 100%;
  height: 100%;
  overflow-x: hidden;
  margin: 0px;
  padding: 0px;
  touch-action: manipulation;
}

body {
  font-size: 16px;
  font-family: "Roboto Slab", serif;
  width: 100%;
  height: 100%;
  margin: 0;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-wrap: break-word;
  color: #333;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
ul,
span,
li,
img,
inpot,
button {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.5;
  font-weight: inherit;
}

h1,
h2,
h3 {
  font-family: "Allura", cursive;
}

p {
  line-height: 2;
  font-size: 1.05em;
  font-weight: 400;
  color: #555;
}

h1 {
  font-size: 6em;
  line-height: 1;
}
h2 {
  font-size: 3.5em;
  line-height: 1.1;
}
h3 {
  font-size: 2.5em;
}
h4 {
  font-size: 1.5em;
}
h5 {
  font-size: 1.2em;
}
h6 {
  font-size: 0.9em;
  letter-spacing: 1px;
}

a,
button {
  display: inline-block;
  text-decoration: none;
  color: inherit;
  transition: all 0.3s;
  line-height: 1;
}

a:focus,
a:active,
a:hover,
button:focus,
button:active,
button:hover,
a b.light-color:hover {
  text-decoration: none;
  color: #ffbaba;
}

b {
  font-weight: 500;
}

img {
  width: 100%;
}

li {
  list-style: none;
  display: inline-block;
}

span {
  display: inline-block;
}

button {
  outline: 0;
  border: 0;
  background: none;
  cursor: pointer;
}

b.light-color {
  color: #444;
}

.icon {
  font-size: 1.1em;
  display: inline-block;
  line-height: inherit;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  line-height: inherit;
}

.menu-logo {
  margin-top: -5px;
}

/* ---------------------------------
2. COMMONS FOR PAGE DESIGN
--------------------------------- */

.pre-title {
  font-size: 3em;
}

.btn {
  padding: 15px 30px;
  border-radius: 1px;
  letter-spacing: 2px;
  border: 1px solid #ffbaba;
  background: #ffbaba;
  color: #fff;
}

.btn:hover {
  color: #ffbaba;
  background: none;
}

.btn-2 {
  font-size: 0.9em;
  padding: 15px 30px;
  border-radius: 1px;
  letter-spacing: 2px;
  border: 1px solid #ffbaba;
}

.btn-2:hover {
  background: #ffbaba;
  color: #fff;
}

.border-btn {
  font-size: 0.9em;
  padding-bottom: 5px;
  background: none;
  color: inherit;
  border: 0;
  position: relative;
  overflow: hidden;
}

.border-btn:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  transition: all 0.3s;
  background: #ffbaba;
}

.border-btn:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  transform: translateX(-130%);
  transition: all 0.3s;
  background: #ffbaba;
}

.border-btn:hover:after {
  transform: translateX(130%);
}

.border-btn:hover:before {
  transform: translateX(0%);
}

.section {
  padding: 70px 0 70px;
}

.section .heading {
  padding-bottom: 40px;
}

.section .heading .icon {
  font-size: 18px;
  margin-top: 20px;
  color: #ffbaba;
}

.section .heading .icon.color-white {
  color: #fff;
}

.section .heading .heading-bottom {
  margin-top: 20px;
  display: inline-block;
  width: 160px;
  height: 20px;
  line-height: 20px;
  position: relative;
}

.section .heading .heading-bottom .icon {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -9px 0 0 -9px;
}

.section .heading .heading-bottom:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -0.5px;
  height: 1px;
  width: 60px;
  background: #ffffff;
}

.section .heading .heading-bottom:before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -0.5px;
  height: 1px;
  width: 60px;
  background: #ffffff;
}

.section .heading .heading-bottom-dark {
  margin-top: 20px;
  display: inline-block;
  width: 160px;
  height: 20px;
  line-height: 20px;
  position: relative;
}

.section .heading .heading-bottom-dark .icon {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -9px 0 0 -9px;
}

.section .heading .heading-bottom-dark:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -0.5px;
  height: 1px;
  width: 60px;
  background: gray;
}

.section .heading .heading-bottom-dark:before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -0.5px;
  height: 1px;
  width: 60px;
  background: gray;
}

.section .margin-bottom {
  margin-bottom: 30px;
}

.center-text {
  text-align: center;
}

.color-white {
  color: #fff;
}

.display-table {
  display: table;
  height: 100%;
  width: 100%;
}

.display-table-cell {
  display: table-cell;
  vertical-align: middle;
}

::-webkit-input-placeholder {
  font-size: 0.9em;
  letter-spacing: 1px;
}

::-moz-placeholder {
  font-size: 0.9em;
  letter-spacing: 1px;
}

:-ms-input-placeholder {
  font-size: 0.9em;
  letter-spacing: 1px;
}

:-moz-placeholder {
  font-size: 0.9em;
  letter-spacing: 1px;
}

/* JQUERY LIGHT BOX */

#flavorOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  background: rgba(0, 0, 0, 0.8);
}

#flavorLightbox {
  display: none;
}

#flavorLightbox > img {
  max-width: 90%;
  max-height: 90%;
  position: fixed;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
}

#flavorLightboxClose {
  font-size: 32px;
  cursor: pointer;
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 666;
  color: #fff;
}

.thumbnails {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  z-index: 600;
  position: relative;
  cursor: pointer;
  position: absolute;
  bottom: 50px;
  width: 90%;
}

.thumbnails img {
  height: 50px;
  margin: 1px;
  transition: 0.3s;
}

.thumbnails img:hover {
  border: 2px solid #fff;
}

/* ---------------------------------
3. MENU
--------------------------------- */

header {
  position: absolute;
  left: 0;
  right: 0;
  padding-top: 20px;
  /*top: 20px;*/
  z-index: 1000;
  font-size: 0.95em;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
  background: rgb(33, 32, 45);
  background: -moz-linear-gradient(180deg, rgba(33, 32, 45, 1) 0%, rgba(0, 212, 255, 0) 100%);
  background: -webkit-linear-gradient(180deg, rgba(33, 32, 45, 1) 0%, rgba(0, 212, 255, 0) 100%);
  background: linear-gradient(180deg, rgba(33, 32, 45, 1) 0%, rgba(0, 212, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#21202d",endColorstr="#00d4ff",GradientType=1);
}

/* MIDDLE AREA */

header .logo {
  margin: 5px 0 20px;
  height: 40px;
  text-shadow: 2px 2px 20px black;
}

header .logo img {
  height: 100%;
  width: auto;
}

/* BOTTOM AREA */

header .main-menu {
  font-weight: 500;
  float: right;
  margin: 0px -15px;
  text-shadow: 2px 2px 20px black;
}

header .main-menu > li > a {
  height: 50px;
  line-height: 46px;
  padding: 0 15px;
  border: 2px solid transparent;
  border-radius: 2px;
  background: transparent;
}

header .visible.main-menu {
  display: block;
}

/* DROPDOWN MENU STYLING */

header .main-menu li.drop-down {
  position: relative;
  text-align: left;
}

header .main-menu li.drop-down > ul.drop-down-menu {
  display: none;
  position: absolute;
  top: 50px;
  left: 0;
  min-width: 200px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
  background: #333;
}

header .main-menu li.drop-down > ul.drop-down-menu li {
  display: block;
  border-top: 1px solid #444;
}

header .main-menu li.drop-down > ul.drop-down-menu li > a {
  display: block;
  padding: 15px 20px;
}

header .main-menu li.drop-down > ul.drop-down-inner li:first-child {
  border-top: 0;
}

header .main-menu li i {
  margin-left: 10px;
}

/* DROPDOWN SUBMENU */

header .main-menu li.drop-down > ul.drop-down-inner {
  top: 0;
  left: auto;
  left: 100%;
}

/* DROPDOWN HOVER */

header .main-menu > li > a:hover,
header .main-menu li a.mouseover {
  border-color: #ffbaba;
}

header .main-menu li.drop-down a.mouseover + ul.drop-down-menu {
  display: block;
  animation: full-opacity-anim 0.2s forwards;
}

@keyframes full-opacity-anim {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* NAV ICON */

.menu-nav-icon {
  display: none;
  height: 60px;
  width: 50px;
  text-align: center;
  line-height: 60px;
  cursor: pointer;
  font-size: 1.8em;
}

/* ---------------------------------
4. SLIDER
--------------------------------- */

.main-slider {
  height: 100vh;
  position: relative;
  z-index: 1;
  background-image: url(../images/landing-cover.jpg);
  background-size: cover;
  background-position: center 20%;
  background-repeat: no-repeat;
  color: #fff;
  /*text-shadow: 2px 2px 20px black;*/
}

.main-slider:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background: rgba(0, 0, 10, 0.2);
}

.slider-content .date {
  margin-bottom: 10px;
  font-size: 2.4em;
}

.slider-content i {
  line-height: 1;
}

.slider-content i.small-icon {
  font-size: 2.5em;
}

.slider-content .title i {
  transform: translateY(10px);
  font-size: 0.8em;
  color: #ffbaba;
}

.regular-slider {
  height: 500px;
  position: relative;
  z-index: 1;
  background-image: url(../images/guests-cover.jpg);
  background-size: cover;
  background-position: 50% 53%;
  color: #fff;
}

.regular-slider:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.2);
}

.regular-slider .slider-content i {
  margin-right: 10px;
  transform: translateY(3px);
}

/* ---------------------------------
5. CONTENT AREA
--------------------------------- */

.regular-area {
  padding: 0 0 100px 0;
  margin-top: -50px;
  position: relative;
  z-index: 10;
}

.regular-area .content {
  padding: 40px 70px;
  box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.2);
  background: #fff;
}

.content .margin-top-btm {
  margin: 20px 0;
}

.content .img-wrapper {
  margin: 30px 0;
}

.content .quoto-area {
  position: relative;
  padding-left: 50px;
}

.content .quoto-area .quoto {
  font-size: 2em;
}

.content .quoto-area .quoto i {
  position: absolute;
  top: -10px;
  left: 0;
  transform: rotate(180deg);
  color: #e45f74;
}

.content .quoto-area .name {
  margin-top: 20px;
  color: #666;
}

.content .braidmaids-name li {
  display: block;
  margin: 10px 0;
}

.content .braidmaids-name li:first-child:before {
  display: none;
}

.content .braidmaids-name li:before {
  content: "";
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 10px;
  margin-right: 15px;
  border: 1px solid #888;
}

/* ---------------------------------
5. STORY AREA
--------------------------------- */

.story-area {
  position: relative;
  z-index: 1;
}

.story-area:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  opacity: 0.8;
  background: url(../images/bg-circle.png);
}

/* ---------------------------------
6. REMAINING TIME
--------------------------------- */

.counter-area {
  position: relative;
  z-index: 1;
  background-image: url(../images/regular-cover.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 38%;
  color: #fff;
  /*text-shadow: 2px 2px 20px black;*/
}

.counter-area:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background: rgba(0, 40, 30, 0.3);
}

.remaining-time .time-sec {
  position: relative;
  display: inline-block;
  margin: 0 20px;
  margin-bottom: 30px;
  height: 120px;
  width: 120px;
  padding-top: 25px;
  border-radius: 100px;
  border: 1px solid #fff;
}

.remaining-time .time-sec .title {
  font-size: 3em;
  line-height: 1;
  display: block;
}

/* ---------------------------------
7. WEDDING DETAILS AREA
--------------------------------- */

.wedding-details {
  overflow: hidden;
  position: relative;
}

.wedding-details:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -0.5px;
  width: 1px;
  background: #ccc;
}

.w-detail {
  width: 50%;
  padding: 0 50px;
  margin-bottom: 30px;
  position: relative;
  clear: both;
  margin-top: -40px;
}

.w-detail:first-child {
  margin-top: 0;
}

.w-detail:last-child {
  margin-bottom: 0;
}

.w-detail:after {
  content: "";
  position: absolute;
  top: 0;
  height: 18px;
  width: 18px;
  border-radius: 20px;
  z-index: 1;
  border: 1px solid #aaa;
  background: #fff;
}

.w-detail.right {
  text-align: left;
  float: right;
}

.w-detail.right:after {
  left: 0;
  margin-left: -9px;
}

.w-detail.left {
  text-align: right;
  float: left;
}

.w-detail.left:after {
  right: 0;
  margin-right: -9px;
}

.w-detail .title {
  margin: 10px 0;
}

.w-detail .icon {
  font-size: 3.5em;
  line-height: 1;
  color: #ffbaba;
}

/* ---------------------------------
8. WEDDING CEREMONY
--------------------------------- */

.ceremony-area {
  position: relative;
  z-index: 1;
  background-image: url(../images/regular-cover.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  /*text-shadow: 2px 2px 10px black;*/
  color: #fff;
}

.ceremony-area:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background: rgba(0, 40, 30, 0.3);
}

.ceremony .desc {
  /*margin-bottom: 15px;*/
  color: #fff;
}

/* ---------------------------------
9. CONTACT AREA
--------------------------------- */

.contact-area {
  position: relative;
  overflow: hidden;
  box-shadow: 1px 10px 40px rgba(0, 0, 0, 0.4);
}

.contact-area .contact-wrapper {
  width: 50%;
}

.contact-area .map-area {
  width: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.contact-area .phone {
  margin: 0;
  font-size: 1.1em;
  color: #ffbaba;
}

/* ---------------------------------
10. VIEW GALLERY
--------------------------------- */

.galery-area .gallery-btn {
  margin-top: 30px;
}

/* ---------------------------------
11. FOOTER
--------------------------------- */

.bg {
  height: auto !important;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
}

footer {
  position: relative;
  z-index: 1;
  padding-top: 50px;
}

footer:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  opacity: 0.8;
  background: url(../images/bg-circle.png);
}

footer ul > li > a {
  font-size: 0.9em;
  padding: 10px 15px;
}

footer .logo-wrapper .icon {
  color: #ffbaba;
}

footer .logo {
  display: block;
  height: 50px;
}

footer .logo img {
  height: 100%;
  width: auto;
}

footer .newsletter {
  margin: 20px 0;
}

footer .newsletter .title {
  margin: 5px 0;
}

footer .newsletter .email-area {
  position: relative;
  width: 400px;
  height: 55px;
  margin: 20px auto;
}

footer .email-area .email-input {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  border: 0;
  outline: 0;
  border-radius: 100px;
  padding: 0 70px 0 25px;
  transition: all 0.2s;
  box-shadow: 1px 10px 30px rgba(0, 0, 0, 0.2);
}

footer .email-area .email-input:focus {
  box-shadow: 1px 15px 30px rgba(0, 0, 0, 0.3);
}

footer .email-area .submit-btn {
  position: absolute;
  right: 0;
  height: 55px;
  width: 55px;
  line-height: 55px;
  border-radius: 100px;
  background: #ffbaba;
  color: #fff;
}

footer .email-area .submit-btn:hover {
  background: #000;
  color: #ffbaba;
}

footer .copyright {
  padding: 20px 0 20px;
}
